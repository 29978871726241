/* custom.css */

/* Theme Name: Zodkoo - Responsive Landing page template
   Author: Coderthemes
   Author e-mail: coderthemes@gmail.com
   Version: 1.2.0
   Created: May 2016
   File Description:Main CSS file of the template
*/

/*------------------------------------------------------------------

[Table of contents]

1. Reset
2. Helper classes 
3. Buttons
4. Dropdown
5. Navbar Custom
6. Home
7. FEATURES
8. Pricing
9. Testimonials
10. SUBSCRIBE
11. Footer
12. Other pages CSS
    12.1 Contact
    12.2 Team
    12.3 FAQ
    12.4 JOB
    12.5 Intro Form
13. Responsive

-------------------------------------------------------------------*/


/*======= 1.Reset ======== */
body {
  font-family: 'Roboto', sans-serif;
  color: #496174;
  background: #fff;
  font-size: 14px;
  line-height: 22px;
  overflow-x:hidden;
}

::selection{
  background: #337ab7;
  color: #ffffff;
}

::-moz-selection {
  background: #337ab7;
  color: #ffffff;
}

a:hover,a:focus,.a:active {
  text-decoration: none;
  outline: none !important;
}

/* Back to top */
.back-to-top {
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: none;
  text-align: center;
  z-index: 10000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #496174;
}

.back-to-top i {
  color: #fff;
  font-size: 22px;
  display: block;
  line-height: 30px;
}


/*======= 2.Helper classes ======== */
.section {
  padding-top: 50px;
  padding-bottom: 40px;
}

.section.no-padding-top {
  padding-top: 0;
}

.section.padding-top {
  padding-top: 15px;
}

pageresponse + section.section {
	padding-top: 15px;
}

.sub-title {
  margin-bottom: 40px;
  font-size: 15px;
}

.title {
  font-size: 22px;
  margin-top: 0;
}

.text-white {
  color: #ffffff !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.7);
}

.font-light {
  font-weight: 300;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-0 {
  margin-bottom: 0;
}

.p-0 {
  padding: 0 !important;
}

.w-full {
  width: 100% !important;
}

/* Background Images */
/* .bg-img-1 {
  background: url(/images/bg4.jpg) no-repeat;
  background-size: cover;
}
.bg-img-2 {
  background: url(/images/bg1.jpg) center no-repeat;
  background-size: cover;
} */

/*======= 3.Buttons ======= */
.btn {
  border-radius: 2px;
  padding: 8px 16px;
  outline: none !important;
  box-shadow: none !important;
}

form .btn {
	border-radius: 4px;
	padding: 6px 12px;
}

form .input-group-btn > .btn {
	padding-bottom: 5px;
}

form .btn.active:not(.btn-link) {
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
}

.btn-group > .btn-link:not(:last-child) {
  border-right-color: #496174;
}

.btn-group > .btn-link.active {
  color: #496174;
}

.btn-sm {
  padding: 5px 10px !important;
}

.btn-2x {
	font-size: 28px;
}

.btn-white-fill {
  padding: 8px 24px !important;
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.btn-white-fill:hover {
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  background-color: transparent;
  color: #ffffff !important;
}
.panel-footer > .btn-white-fill:hover {
  color: initial !important;
}


.btn-blue-fill {
  padding: 8px 24px !important;
  background-color: #12457a;
  border: 2px solid #12457a !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #ffffff !important;
}

.btn-blue-fill:hover {
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  background-color: #757575 !important;
  color: #ffffff !important;
}

.btn-blue-fill:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff;
  color: #12457a !important;
}

.btn-white-fill-custom {
  padding: 8px 24px !important;
  background: rgb(18, 69, 122); /* fallback color */
  background: rgba(18, 69, 122, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #ffffff !important;
}

.btn-white-fill-custom:hover {
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  background-color: #757575;
  color: #ffffff !important;
}

.btn-white-fill-custom:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff;
  color: #12457a !important;
}

.btn-white-bordered {
  padding: 8px 24px !important;
  background-color: transparent !important;
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.btn-white-bordered:hover {
  background-color: #ffffff !important;
}

.btn-custom {
  padding: 8px 24px !important;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.btn-custom:hover {
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  background-color: #757575;
  color: #ffffff !important;
}

.btn-custom:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
}

.btn-custom:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
}

.btn-custom:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
}

.video-btn {
  color: #ffffff !important;
  letter-spacing: 1px;
  outline: none !important;
}

.video-btn i {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 17px;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  padding-left: 3px;
  margin-left: -12px;
}

.img-bg {
  border-radius: 10px;
  background-color: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.people-say {
  background-image: url(../assets/images/BF0430E8-F6A3-AF35-0CD0D04DD6B57D7F.jpg)
}

.merchant-say {
  background-image: url(../assets/images/BE4C5B40-ABAD-1FBB-A90D09BBC22AF972.jpg)
}

.text-center > .img-bg {
	margin-left: auto;
	margin-right: auto;
}

.btn-link {
  text-decoration: underline;
  padding: initial !important;
  border: none;
  font-size: inherit;
  vertical-align: unset;
}

/*======= 4.Dropdown ======= */
/*
.dropdown-menu {
  box-shadow: none;
  padding: 4px;
  border-radius: 4px !important;
  -webkit-animation: dropdownOpen 0.3s ease-out;
  -o-animation: dropdownOpen 0.3s ease-out;
  animation: dropdownOpen 0.3s ease-out;
  border: 2px solid #eee;
}

.dropdown-menu > li > a {
  padding: 6px 20px;
  font-size: 15px !important;
  color: #496174 !important;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  color: #ffffff !important;
}

@-webkit-keyframes dropdownOpen {
  0% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes dropdownOpen {
  0% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
*/

/* Modals */
.modal .modal-dialog .modal-content {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #DDDDDD;
  border-radius: 2px;
  box-shadow: none;
  padding: 25px;
}

.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 2px;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 20px 0;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 0;
  padding-top: 15px;
}

/*===== 5. Navbar Custom ======*/

.navbar-custom {
  width: 100%;
  border-radius: 0;
  z-index: 999;
  padding: 20px 0;
  margin-bottom: 0;
}

.navbar-custom, main > section:first-child {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.navbar-custom .navbar-nav li a {
  color: rgba(18, 69, 122, 0.75);
  font-size: 15px;
  margin: 5px 0;
}

.navbar-custom .nav > li > a:focus, .navbar-custom .nav > li > a:hover, .navbar-custom .navbar-nav li.active > a {
  background-color: transparent;
  color: #12457a;
}

.navbar-toggle .icon-bar {
  background-color: #12457a;
}

.navbar-btn-alt {
  padding: 5px 20px !important;
  text-transform: none !important;
  font-weight: 400;
  margin-top: 8px !important;
}

.logo {
  background-image: url(../assets/allegro-credit.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 50px;
  min-width: 220px;
}

.worry-free {
  background-image: url(../assets/images/CBBBF09D-F27D-4A4B-D23F2FA4C9BF1857.jpg)
}

.payment-estimator {
  background-image: url(../assets/images/BE3CE385-B14B-CF2C-72DF15E58535E54D.jpg);
}

.grow-business {
  background-image: url(../assets/images/BE3CE385-B14B-CF2C-72DF15E58535E54D.jpg);
}

.navbar-toggle {
  font-size: 30px;
  outline: none;
}

.navbar-custom .btn-custom {
  margin-top: 8px;
  margin-left: 20px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
  border-color: #337ab7;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.sticky-wrapper.is-sticky .navbar-custom {
  padding: 10px 0 !important;
}

header > #sticky-nav-sticky-wrapper > #sticky-nav > .container > #navbar-menu > ul.nav > li > a[href$=allwell] > span {
	/* background-image: url(/images/allwell-nav.png); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
}

header > #sticky-nav-sticky-wrapper > #sticky-nav > .container > #navbar-menu > ul.nav > li > a[href$=allwell] > span > span {
  visibility: hidden;
  display: inline-block;
  padding: 5px 15px;
}


/*======= Experience =======*/

.experience {
  background-color: #12457a;
}

.experience .title{
  color: white; 
}

.experience .features-box{
  color: white !important; 
}

/*======= 6. Home =======*/

main > pageresponse {
  display: block;
  background-color: #f5f5f5;
}

main > pageresponse > .row > div:empty {
  display: none;
}

main > pageresponse > .row > div > div.alert {
  margin: 0;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

main > pageresponse, main > section:first-child {
	padding-top: 91px;
}

body.scrolled > main > section:first-child {
  padding-top: 71px;
}

.text-center > span.bg-white {
	display: inline-block;
	margin: 0 auto 15px auto;
	padding: 10px;
	border-radius: 10px;
	background-color: #ffffff;
}

.hero {
  background-attachment: scroll;
  background-posiont: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.hero-fullscreen {
  height: 100%;
  min-height: 400px;
}

.hero-wrapper {
  padding: 100px 0;
}

.hero-sm .hero-wrapper {
  padding: 50px;
}
.hero-sm .h1 {
  margin-top: 30px !important;
}
.hero-wrapper-alt{
  position: static;
}

.hero-wrapper h1{
  line-height: 46px;
  font-weight: 800;
  width: 70%;
  margin: 0 auto;
  color: white; 
}

.hero-wrapper h4, .hero-wrapper p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  margin: 30px auto 50px auto;
  color: white; 
}

.carousel-inner.carousel-with-bg > .item {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 7.5%;
}

.hero-sm .carousel-inner.carousel-with-bg > .item {
  /*background-position: top center;*/
}

.carousel-inner.carousel-with-bg > .item > .item-content {
  display: block;
  height: 400px;
  line-height: 1;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}

.hero-sm .carousel-inner.carousel-with-bg > .item > .item-content {
  height: 146px;
}

.carousel-inner > .item > .item-content blockquote {
	font-size: inherit;
	border-left: 0;
	margin: 0;
	padding: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote {
	text-align: justify;
  position: relative;
  margin: 0 0 30px 0;
  padding: 30px 30px 0 30px;
}

.carousel-inner > .item > .item-content blockquote > div.quote:before,
.carousel-inner > .item > .item-content blockquote > div.quote:after {
  color: #eeeeee;
  font-size: 12em;
  line-height: 100%;
  display: block;
  position: absolute;
  z-index: -1;
}

.carousel-inner > .item > .item-content blockquote > div.quote:before {
	content: open-quote;
	top: 0;
	left: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote:after {
  content: close-quote;
  bottom: -0.6em;
  right: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote > p {
	margin: 0 0 20px 0;
}

.carousel-inner > .item > .item-content blockquote > cite {
	display: block;
  margin: 0 30px;
}

.carousel-inner > .item > .item-content blockquote > cite > p {
	margin: 0;
}

.carousel-inner > .item > .item-content div.img-bg {
	width: 100%;
	height: 300px;
}

.carousel-inner > .item > .item-content div.team-member > div.img-bg {
	background-position: center top;
}

.carousel > .carousel-control {
	width: 7.5%;
}

.carousel > .container {
	padding-top: 30px;
	padding-bottom: 75px;
}

.carousel > .container.no-padding-bottom {
  padding-bottom: 0;
}

.carousel-indicators.dark > li {
	border-color: #999999;
}

.carousel-indicators.dark > li.active {
	background-color: #999999;
}

.full-screen {
  display: table;
  height: 100%;
  width: 100%;
}

.or-space {
  margin: 0 20px;
}

iframe {
  max-width: 100%;
}

.frame-border {
  border: 9px solid rgba(0, 0, 0, 0.3);
  webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

/* ==== 7. FEATURES ==== */

.feat-description p {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 26px;
}

.feat-description h4 {
  font-weight: 300;
  line-height: 28px;
}

.title-box-icon i {
  font-size: 48px;
  margin-bottom: 20px;
}

.title-box-icon h3 {
  margin-bottom: 70px;
}

.features-box {
  margin-top: 30px;
  padding: 20px;
  text-align: center;
}

.row.boxed + hr {
  display: none;
}

@media (min-width: 768px) {
  .row.boxed {
  	margin: 15px;
  }
  
  .row.boxed:first-child:not(:last-child) {
  	margin-top: 30px;
  }
  
  .row.boxed:last-child:not(:first-child) {
  	margin-bottom: 30px;
  }
  
  .row.boxed > div:not(:first-child) {
  	border-left: 1px solid #eeeeee;
  	margin-left: -1px;
  }
  
  .row.boxed > div > .features-box {
  	margin-top: 0;
  }
  
  .row.boxed + hr {
    display: block;
  	margin-top: 0;
  	margin-bottom: 0;
  }
  
  .section.experience .row.boxed > div:not(:first-child) {
    border-left-color: #dddddd;
  }
  
  .section.experience .row.boxed + hr {
    border-color: #dddddd;
  }
}

.features-box.text-left {
	text-align: left;
}

.features-box i {
  font-size: 48px;
}

.features-box.text-left i.pull-left {
	margin-right: 15px;
	font-size: 60px;
}

@media (max-width: 1199px) {
  .features-box.text-left {
    text-align: center;
  }
  
  .features-box.text-left i.pull-left {
    margin-right: 0;
    margin-bottom: 5px;
    float: none !important;
  }
}

.features-box.text-left h4 {
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 30px;
}

.features-box.text-left p {
	margin-bottom: 0;
}

/* ==== 8. Pricing === */

.pricing-column {
  position: relative;
  margin-bottom: 40px;
}

.pricing-column .inner-box {
  position: relative;
  margin: 20px auto 0 auto;
  max-width: 320px;
  padding: 0 30px 50px;
  border: 2px solid #95A8B7;
}

.inner-box p {
  padding: 0 20px;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  color: #7f7f7f;
  margin-bottom: 30px;
}

.inner-box.active {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}

.pricing-column .plan-header {
  position: relative;
  padding: 30px 20px 25px;
}

.pricing-column .plan-title {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.pricing-column .plan-price {
  font-size: 38px;
  margin-bottom: 10px;
  font-weight: 700;
}

.pricing-column .plan-duration {
  font-size: 13px;
  color: #98a6ad;
}

.pricing-column .plan-stats {
  position: relative;
  padding: 30px 20px 15px;
}

.pricing-column .plan-stats li {
  margin-bottom: 15px;
  line-height: 24px;
}

.pricing-column .plan-stats li i {
  font-size: 18px;
  width: 26px;
  vertical-align: middle;
}



/* === 9.Testimonials === */

.testimonial-description {
  margin-top: 50px;
  border-radius: 5px;
  padding: 30px 30px 20px 30px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px rgba(222, 222, 222, 0.32);
}

.testimonial-description:after {
  content: "";
  bottom: -15px;
  left: 50px;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.testimonial-description p {
  line-height: 24px;
  font-size: 14px;
  font-style: italic;
}

.testimonial-user-thumb img{
  border-radius:50%;
  width: 60px;
  height: 60px;
}

.testimonial-user-name {
  margin-bottom: 0;
}

.user-thumb,
.user-text{
  display: inline-block;
  vertical-align:bottom;
}

.user-thumb{
  margin-right:15px;
}

.testimonial-user-info{
  padding-left:30px;
  margin-top: 35px;
}

.user-position{
  font-weight:400;
  display: block;
}


/* === 10. SUBSCRIBE === */

.input-subscribe {
  background-color: transparent;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  height: 50px;
  padding-left: 20px;
  box-shadow: none !important;
  margin-bottom: 10px;
}

.input-subscribe:focus {
  border: 2px solid rgba(255, 255, 255, 0.6);
}

input.input-subscribe::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-weight: normal;
}

input.input-subscribe:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

input.input-subscribe::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

input.input-subscribe:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

label.valid {
  color: #ffffff;
  font-weight: normal;
  margin: 10px 0;
}


/*======= 11 Footer =======*/

.footer {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #2f3e47;
}

.footer h5{
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer a{
  color: #ABABAB;
}

.footer a:hover{
  color: rgba(255, 255, 255, 0.6);;
}

.footer ul li {
  margin: 5px 0;
}

.footer-alt {
  margin-top: 30px;
  padding-top: 20px;
}

.footer address {
  color: rgba(255, 255, 255, 0.4);
  line-height: 24px;
  font-size: 13px;
}


/* == 12. Other pages CSS === */

.header-title-box {
  padding-top: 190px;
  padding-bottom: 155px
}

.header-title-box h3 {
  font-size: 30px;
  font-weight: 300;
}

.title-about h3 {
  margin-bottom: 30px;
}

.wide-img-showcase-row {
  position: relative;
}

.no-padding.img {
  /* background: url(/images/showcase-1.jpg) scroll center no-repeat; */
  background-size: cover;
  position: absolute;
  height: 100%;
}

.no-padding.img-2 {
  /* background: url(/images/showcase-2.jpg) scroll center no-repeat; */
  background-size: cover;
  position: absolute;
  height: 100%;
}

.about-detail-img-box {
  padding: 15% 0;
}


/* == 12.1 Contact ==*/
textarea {
  max-width: 100%;
}

.contact-form .form-control {
  height: 42px;
  box-shadow: none;
  border: 2px solid rgba(40, 40, 46, 0.3);
}

textarea.form-control {
  height: auto !important;
}

/*
.error {
  margin: 8px 0;
  display: none;
  color: red;
  font-weight: normal;
}
*/

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0;
}

.contact-box {
  padding: 30px;
}

.contact-detail {
  margin-bottom: 40px;
}
.contact-detail i{
  float: left;
  width: 40px;
  font-size: 24px;
}

.contact-detail p,.contact-detail address{
  overflow: hidden;
}

.contact-detail a {
  color: #496174;
}
.parsley-error {
  border: 2px solid red !important;
}
.parsley-errors-list {
  padding-left: 0;
}
.parsley-errors-list li {
  list-style: none;
  color: red;
  margin-top: 3px;
  font-size: 13px;
}

/*== 12.2 Team ==*/

.team img {
  max-width: 240px;
  margin: 0 auto;
  background-color: #fafafa;
}

.team .team-member {
  margin: 30px 0;
  padding: 0 30px;
}

.team .team-member h4 {
  padding-top: 10px;
  margin-bottom: 5px;
}

.team .team-member p {
  margin-bottom: 0;
}

.team .img-circle {
	border-radius: 50% !important;
	border: 5px solid #f5f5f5;
}

/* ==== 12.3 FAQ ==== */
.question {
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
}

/* === 12.4 JOB ===*/
.job-box {
  padding: 20px 30px;
  background-color: #f3f6fa;
  border: 1px solid #E6EDF3;
  border-radius: 5px;
  margin-bottom: 30px;
}

.job-box h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}

.btn-job {
  padding: 6px 20px !important;
  font-size: 12px;
  text-transform: none;
  margin-top: 10px;
}

/* == 12.5 Intro Form ===*/
.intro-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 0 22px 45px -15px rgba(0,0,0,0.5) !important;
  -moz-box-shadow: 0 22px 45px -15px rgba(0,0,0,0.5) !important;
  box-shadow: 0 22px 45px -15px rgba(0,0,0,0.5) !important;
}

.intro-form h3{
  color: #949799;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 0;
}

.intro-form input {
  border: 1px solid #eee;
  height: 38px;
  box-shadow: none !important;
}


.intro-form .form-group:last-of-type {
  margin-bottom: 0;
}




/*======= 13. Responsive ======*/
@media (min-width: 768px) {
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .nav-custom-left {
    margin-left: 5%;
  }

  main > pageresponse, main > section:first-child {
    padding-top: 91px;
  }
}

@media (max-width: 1199px) {
  .nav-custom-left {
  	clear: left;
    margin-left: -15px;
  }
  .nav-custom-left > li > a {
    padding-left: 0;
    padding-right: 30px;
  }
  
  main > pageresponse, main > section:first-child {
    padding-top: 141px;
  }
  
  body.scrolled > main > section:first-child {
    padding-top: 121px;
  }
}

@media (max-width: 767px) {
  main > pageresponse, main > section:first-child {
    padding-top: 91px;
  }
  
  body.scrolled > main > section:first-child {
    padding-top: 71px;
  }

  .navbar-brand {
    margin-left: 15px;
  }

  .no-padding.img,.no-padding.img-2 {
    position: relative;
    height: 300px;
  }
  .video-wrapper {
    padding-top: 50px;
  }
  .intro-form {
    margin-top: 50px;
  }
  .feat-description {
    margin-bottom: 50px;
    text-align: center;
  }

  .hero-wrapper {
  	padding: 50px 0;
  }
}

a.contact {
  text-decoration: underline;
  font-weight: bold;
  color: #2D6A9F !important;
}
a.outline {
  outline: none !important;
}

.btn-outline{
  outline: none !important;
}

[role="radiogroup"] {
  padding: 0;
  margin: 0;
  list-style: none;
}

[role="radiogroup"]:focus {
  outline: none;
}

[role="radio"] {
  padding: 4px 8px;
  border: 0 solid transparent;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  cursor: default;
  outline: none;
  color: black;
}

[role="radio"] + [role="radio"] {
  margin-left: 1em;
}

[role="radio"]::before {
  position: relative;
  top: 1px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' style='forced-color-adjust: auto;'%3E%3Ccircle cx='7' cy='7' r='6' stroke='rgb(18, 69, 122)' stroke-width='2' fill-opacity='0' /%3E%3C/svg%3E");
}

[role="radio"][aria-checked="true"]::before {
  position: relative;
  top: 1px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' style='forced-color-adjust: auto;'%3E%3Ccircle cx='7' cy='7' r='6' stroke='rgb(18, 69, 122)' stroke-width='2' fill-opacity='0' /%3E%3Ccircle cx='7' cy='7' r='3' fill='rgb(18, 69, 122)' stroke-opacity='0' /%3E%3C/svg%3E");
}
