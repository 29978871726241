body {
  color: #496174;
  background: #fff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  overflow-x: hidden;
}

::selection {
  color: #fff;
  background: #337ab7;
}

a:hover, a:focus, .a:active {
  text-decoration: none;
  outline: none !important;
}

.back-to-top {
  text-align: center;
  z-index: 10000;
  background-color: #496174;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: none;
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.back-to-top i {
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  display: block;
}

.section {
  padding-top: 50px;
  padding-bottom: 40px;
}

.section.no-padding-top {
  padding-top: 0;
}

.section.padding-top, pageresponse + section.section {
  padding-top: 15px;
}

.sub-title {
  margin-bottom: 40px;
  font-size: 15px;
}

.title {
  margin-top: 0;
  font-size: 22px;
}

.text-white {
  color: #fff !important;
}

.text-light {
  color: #ffffffb3;
}

.font-light {
  font-weight: 300;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-0 {
  margin-bottom: 0;
}

.p-0 {
  padding: 0 !important;
}

.w-full {
  width: 100% !important;
}

.btn {
  border-radius: 2px;
  padding: 8px 16px;
  box-shadow: none !important;
  outline: none !important;
}

form .btn {
  border-radius: 4px;
  padding: 6px 12px;
}

form .input-group-btn > .btn {
  padding-bottom: 5px;
}

form .btn.active:not(.btn-link) {
  box-shadow: inset 0 3px 5px #00000020 !important;
}

.btn-group > .btn-link:not(:last-child) {
  border-right-color: #496174;
}

.btn-group > .btn-link.active {
  color: #496174;
}

.btn-sm {
  padding: 5px 10px !important;
}

.btn-2x {
  font-size: 28px;
}

.btn-white-fill {
  text-transform: uppercase;
  letter-spacing: .04em;
  background-color: #fff;
  border-radius: 50px;
  font-weight: 500;
  border: 2px solid #ffffffbf !important;
  padding: 8px 24px !important;
}

.btn-white-fill:hover {
  background-color: #0000;
  color: #fff !important;
  border: 2px solid #ffffffbf !important;
}

.panel-footer > .btn-white-fill:hover {
  color: initial !important;
}

.btn-blue-fill {
  text-transform: uppercase;
  letter-spacing: .04em;
  background-color: #12457a;
  border-radius: 50px;
  font-weight: 500;
  color: #fff !important;
  border: 2px solid #12457a !important;
  padding: 8px 24px !important;
}

.btn-blue-fill:hover {
  color: #fff !important;
  background-color: #757575 !important;
  border: 2px solid #ffffffbf !important;
}

.btn-blue-fill:focus {
  background-color: #fff;
  color: #12457a !important;
  border: 2px solid #12457a !important;
}

.btn-white-fill-custom {
  text-transform: uppercase;
  letter-spacing: .04em;
  background: #12457ab3;
  border-radius: 50px;
  font-weight: 500;
  color: #fff !important;
  border: 2px solid #ffffffbf !important;
  padding: 8px 24px !important;
}

.btn-white-fill-custom:hover {
  background-color: #757575;
  color: #fff !important;
  border: 2px solid #ffffffbf !important;
}

.btn-white-fill-custom:focus {
  background-color: #fff;
  color: #12457a !important;
  border: 2px solid #12457a !important;
}

.btn-white-bordered {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .04em;
  border-radius: 50px;
  font-weight: 500;
  background-color: #0000 !important;
  border: 2px solid #ffffffbf !important;
  padding: 8px 24px !important;
}

.btn-white-bordered:hover {
  background-color: #fff !important;
}

.btn-custom {
  text-transform: uppercase;
  letter-spacing: .04em;
  border-radius: 50px;
  font-weight: 500;
  color: #fff !important;
  padding: 8px 24px !important;
}

.btn-custom:hover {
  background-color: #757575;
  color: #fff !important;
  border: 2px solid #ffffffbf !important;
}

.btn-custom:focus {
  color: #12457a !important;
  background-color: #fff !important;
  border: 2px solid #12457a !important;
}

.video-btn {
  letter-spacing: 1px;
  color: #fff !important;
  outline: none !important;
}

.video-btn i {
  vertical-align: middle;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: -12px;
  margin-right: 7px;
  padding-left: 3px;
  font-size: 12px;
  line-height: 17px;
}

.img-bg {
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.people-say {
  background-image: url("BF0430E8-F6A3-AF35-0CD0D04DD6B57D7F.1fedd9a2.jpg");
}

.merchant-say {
  background-image: url("BE4C5B40-ABAD-1FBB-A90D09BBC22AF972.1f281c6b.jpg");
}

.text-center > .img-bg {
  margin-left: auto;
  margin-right: auto;
}

.btn-link {
  font-size: inherit;
  vertical-align: unset;
  border: none;
  text-decoration: underline;
  padding: initial !important;
}

.modal .modal-dialog .modal-content {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #ddd;
  border-radius: 2px;
  padding: 25px;
}

.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 2px;
  margin: 0;
  padding: 0 0 15px;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 20px 0;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 15px 0 0;
}

.navbar-custom {
  z-index: 999;
  border-radius: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 20px 0;
}

.navbar-custom, main > section:first-child {
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.navbar-custom .navbar-nav li a {
  color: #12457abf;
  margin: 5px 0;
  font-size: 15px;
}

.navbar-custom .nav > li > a:focus, .navbar-custom .nav > li > a:hover, .navbar-custom .navbar-nav li.active > a {
  color: #12457a;
  background-color: #0000;
}

.navbar-toggle .icon-bar {
  background-color: #12457a;
}

.navbar-btn-alt {
  font-weight: 400;
  text-transform: none !important;
  margin-top: 8px !important;
  padding: 5px 20px !important;
}

.logo {
  background-image: url("allegro-credit.0fcdfe95.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 220px;
  min-height: 50px;
}

.worry-free {
  background-image: url("CBBBF09D-F27D-4A4B-D23F2FA4C9BF1857.131accf3.jpg");
}

.payment-estimator, .grow-business {
  background-image: url("BE3CE385-B14B-CF2C-72DF15E58535E54D.6f11d29d.jpg");
}

.navbar-toggle {
  outline: none;
  font-size: 30px;
}

.navbar-custom .btn-custom {
  margin-top: 8px;
  margin-left: 20px;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: #0000;
  border-color: #337ab7;
}

.sticky-wrapper {
  width: 100%;
  position: absolute;
}

.sticky-wrapper.is-sticky .navbar-custom {
  padding: 10px 0 !important;
}

header > #sticky-nav-sticky-wrapper > #sticky-nav > .container > #navbar-menu > ul.nav > li > a[href$="allwell"] > span {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -5px;
  margin-bottom: -5px;
  display: inline-block;
}

header > #sticky-nav-sticky-wrapper > #sticky-nav > .container > #navbar-menu > ul.nav > li > a[href$="allwell"] > span > span {
  visibility: hidden;
  padding: 5px 15px;
  display: inline-block;
}

.experience {
  background-color: #12457a;
}

.experience .title {
  color: #fff;
}

.experience .features-box {
  color: #fff !important;
}

main > pageresponse {
  background-color: #f5f5f5;
  display: block;
}

main > pageresponse > .row > div:empty {
  display: none;
}

main > pageresponse > .row > div > div.alert {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  margin: 0;
}

main > pageresponse, main > section:first-child {
  padding-top: 91px;
}

body.scrolled > main > section:first-child {
  padding-top: 71px;
}

.text-center > span.bg-white {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto 15px;
  padding: 10px;
  display: inline-block;
}

.hero {
  background-posiont: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.hero-fullscreen {
  height: 100%;
  min-height: 400px;
}

.hero-wrapper {
  padding: 100px 0;
}

.hero-sm .hero-wrapper {
  padding: 50px;
}

.hero-sm .h1 {
  margin-top: 30px !important;
}

.hero-wrapper-alt {
  position: static;
}

.hero-wrapper h1 {
  color: #fff;
  width: 70%;
  margin: 0 auto;
  font-weight: 800;
  line-height: 46px;
}

.hero-wrapper h4, .hero-wrapper p {
  color: #fff;
  width: 80%;
  margin: 30px auto 50px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.carousel-inner.carousel-with-bg > .item {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 7.5%;
}

.carousel-inner.carousel-with-bg > .item > .item-content {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 400px;
  line-height: 1;
  display: block;
}

.hero-sm .carousel-inner.carousel-with-bg > .item > .item-content {
  height: 146px;
}

.carousel-inner > .item > .item-content blockquote {
  font-size: inherit;
  border-left: 0;
  margin: 0;
  padding: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote {
  text-align: justify;
  margin: 0 0 30px;
  padding: 30px 30px 0;
  position: relative;
}

.carousel-inner > .item > .item-content blockquote > div.quote:before, .carousel-inner > .item > .item-content blockquote > div.quote:after {
  color: #eee;
  z-index: -1;
  font-size: 12em;
  line-height: 100%;
  display: block;
  position: absolute;
}

.carousel-inner > .item > .item-content blockquote > div.quote:before {
  content: open-quote;
  top: 0;
  left: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote:after {
  content: close-quote;
  bottom: -.6em;
  right: 0;
}

.carousel-inner > .item > .item-content blockquote > div.quote > p {
  margin: 0 0 20px;
}

.carousel-inner > .item > .item-content blockquote > cite {
  margin: 0 30px;
  display: block;
}

.carousel-inner > .item > .item-content blockquote > cite > p {
  margin: 0;
}

.carousel-inner > .item > .item-content div.img-bg {
  width: 100%;
  height: 300px;
}

.carousel-inner > .item > .item-content div.team-member > div.img-bg {
  background-position: top;
}

.carousel > .carousel-control {
  width: 7.5%;
}

.carousel > .container {
  padding-top: 30px;
  padding-bottom: 75px;
}

.carousel > .container.no-padding-bottom {
  padding-bottom: 0;
}

.carousel-indicators.dark > li {
  border-color: #999;
}

.carousel-indicators.dark > li.active {
  background-color: #999;
}

.full-screen {
  width: 100%;
  height: 100%;
  display: table;
}

.or-space {
  margin: 0 20px;
}

iframe {
  max-width: 100%;
}

.frame-border {
  webkit-border-radius: 5px 5px 5px 5px;
  border: 9px solid #0000004d;
  border-radius: 5px;
}

.feat-description p {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 26px;
}

.feat-description h4 {
  font-weight: 300;
  line-height: 28px;
}

.title-box-icon i {
  margin-bottom: 20px;
  font-size: 48px;
}

.title-box-icon h3 {
  margin-bottom: 70px;
}

.features-box {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
}

.row.boxed + hr {
  display: none;
}

@media (width >= 768px) {
  .row.boxed {
    margin: 15px;
  }

  .row.boxed:first-child:not(:last-child) {
    margin-top: 30px;
  }

  .row.boxed:last-child:not(:first-child) {
    margin-bottom: 30px;
  }

  .row.boxed > div:not(:first-child) {
    border-left: 1px solid #eee;
    margin-left: -1px;
  }

  .row.boxed > div > .features-box {
    margin-top: 0;
  }

  .row.boxed + hr {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
  }

  .section.experience .row.boxed > div:not(:first-child) {
    border-left-color: #ddd;
  }

  .section.experience .row.boxed + hr {
    border-color: #ddd;
  }
}

.features-box.text-left {
  text-align: left;
}

.features-box i {
  font-size: 48px;
}

.features-box.text-left i.pull-left {
  margin-right: 15px;
  font-size: 60px;
}

@media (width <= 1199px) {
  .features-box.text-left {
    text-align: center;
  }

  .features-box.text-left i.pull-left {
    margin-bottom: 5px;
    margin-right: 0;
    float: none !important;
  }
}

.features-box.text-left h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 30px;
}

.features-box.text-left p {
  margin-bottom: 0;
}

.pricing-column {
  margin-bottom: 40px;
  position: relative;
}

.pricing-column .inner-box {
  border: 2px solid #95a8b7;
  max-width: 320px;
  margin: 20px auto 0;
  padding: 0 30px 50px;
  position: relative;
}

.inner-box p {
  text-align: center;
  color: #7f7f7f;
  margin-bottom: 30px;
  padding: 0 20px;
  font-size: 15px;
  line-height: 26px;
}

.inner-box.active {
  transform: scale(1.05);
}

.pricing-column .plan-header {
  padding: 30px 20px 25px;
  position: relative;
}

.pricing-column .plan-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}

.pricing-column .plan-price {
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: 700;
}

.pricing-column .plan-duration {
  color: #98a6ad;
  font-size: 13px;
}

.pricing-column .plan-stats {
  padding: 30px 20px 15px;
  position: relative;
}

.pricing-column .plan-stats li {
  margin-bottom: 15px;
  line-height: 24px;
}

.pricing-column .plan-stats li i {
  vertical-align: middle;
  width: 26px;
  font-size: 18px;
}

.testimonial-description {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 30px 30px 20px;
  position: relative;
  box-shadow: 1px 1px 2px #dedede52;
}

.testimonial-description:after {
  content: "";
  border-top: 15px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 50px;
}

.testimonial-description p {
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
}

.testimonial-user-thumb img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.testimonial-user-name {
  margin-bottom: 0;
}

.user-thumb, .user-text {
  vertical-align: bottom;
  display: inline-block;
}

.user-thumb {
  margin-right: 15px;
}

.testimonial-user-info {
  margin-top: 35px;
  padding-left: 30px;
}

.user-position {
  font-weight: 400;
  display: block;
}

.input-subscribe {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fff6;
  height: 50px;
  margin-bottom: 10px;
  padding-left: 20px;
  box-shadow: none !important;
}

.input-subscribe:focus {
  border: 2px solid #fff9;
}

input.input-subscribe::-webkit-input-placeholder {
  color: #fff6;
  font-weight: normal;
}

input.input-subscribe:-moz-placeholder {
  color: #fff6;
}

input.input-subscribe::-moz-placeholder {
  color: #fff6;
}

input.input-subscribe:-ms-input-placeholder {
  color: #fff6;
}

label.valid {
  color: #fff;
  margin: 10px 0;
  font-weight: normal;
}

.footer {
  background-color: #2f3e47;
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer h5 {
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
}

.footer a {
  color: #ababab;
}

.footer a:hover {
  color: #fff9;
}

.footer ul li {
  margin: 5px 0;
}

.footer-alt {
  margin-top: 30px;
  padding-top: 20px;
}

.footer address {
  color: #fff6;
  font-size: 13px;
  line-height: 24px;
}

.header-title-box {
  padding-top: 190px;
  padding-bottom: 155px;
}

.header-title-box h3 {
  font-size: 30px;
  font-weight: 300;
}

.title-about h3 {
  margin-bottom: 30px;
}

.wide-img-showcase-row {
  position: relative;
}

.no-padding.img, .no-padding.img-2 {
  background-size: cover;
  height: 100%;
  position: absolute;
}

.about-detail-img-box {
  padding: 15% 0;
}

textarea {
  max-width: 100%;
}

.contact-form .form-control {
  height: 42px;
  box-shadow: none;
  border: 2px solid #28282e4d;
}

textarea.form-control {
  height: auto !important;
}

#ajaxsuccess {
  clear: both;
  width: 100%;
  margin: 8px 0;
  font-size: 16px;
  display: none;
}

.contact-box {
  padding: 30px;
}

.contact-detail {
  margin-bottom: 40px;
}

.contact-detail i {
  float: left;
  width: 40px;
  font-size: 24px;
}

.contact-detail p, .contact-detail address {
  overflow: hidden;
}

.contact-detail a {
  color: #496174;
}

.parsley-error {
  border: 2px solid red !important;
}

.parsley-errors-list {
  padding-left: 0;
}

.parsley-errors-list li {
  color: red;
  margin-top: 3px;
  font-size: 13px;
  list-style: none;
}

.team img {
  background-color: #fafafa;
  max-width: 240px;
  margin: 0 auto;
}

.team .team-member {
  margin: 30px 0;
  padding: 0 30px;
}

.team .team-member h4 {
  margin-bottom: 5px;
  padding-top: 10px;
}

.team .team-member p {
  margin-bottom: 0;
}

.team .img-circle {
  border: 5px solid #f5f5f5;
  border-radius: 50% !important;
}

.question {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
}

.job-box {
  background-color: #f3f6fa;
  border: 1px solid #e6edf3;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 20px 30px;
}

.job-box h5 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
}

.btn-job {
  text-transform: none;
  margin-top: 10px;
  font-size: 12px;
  padding: 6px 20px !important;
}

.intro-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 22px 45px -15px #00000080 !important;
}

.intro-form h3 {
  color: #949799;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
}

.intro-form input {
  border: 1px solid #eee;
  height: 38px;
  box-shadow: none !important;
}

.intro-form .form-group:last-of-type {
  margin-bottom: 0;
}

@media (width >= 768px) {
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (width >= 1200px) {
  .nav-custom-left {
    margin-left: 5%;
  }

  main > pageresponse, main > section:first-child {
    padding-top: 91px;
  }
}

@media (width <= 1199px) {
  .nav-custom-left {
    clear: left;
    margin-left: -15px;
  }

  .nav-custom-left > li > a {
    padding-left: 0;
    padding-right: 30px;
  }

  main > pageresponse, main > section:first-child {
    padding-top: 141px;
  }

  body.scrolled > main > section:first-child {
    padding-top: 121px;
  }
}

@media (width <= 767px) {
  main > pageresponse, main > section:first-child {
    padding-top: 91px;
  }

  body.scrolled > main > section:first-child {
    padding-top: 71px;
  }

  .navbar-brand {
    margin-left: 15px;
  }

  .no-padding.img, .no-padding.img-2 {
    height: 300px;
    position: relative;
  }

  .video-wrapper {
    padding-top: 50px;
  }

  .intro-form {
    margin-top: 50px;
  }

  .feat-description {
    text-align: center;
    margin-bottom: 50px;
  }

  .hero-wrapper {
    padding: 50px 0;
  }
}

a.contact {
  font-weight: bold;
  text-decoration: underline;
  color: #2d6a9f !important;
}

a.outline, .btn-outline {
  outline: none !important;
}

[role="radiogroup"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

[role="radiogroup"]:focus {
  outline: none;
}

[role="radio"] {
  cursor: default;
  color: #000;
  border: 0 solid #0000;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  display: inline-block;
  position: relative;
}

[role="radio"] + [role="radio"] {
  margin-left: 1em;
}

[role="radio"]:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' style='forced-color-adjust: auto;'%3E%3Ccircle cx='7' cy='7' r='6' stroke='rgb(18, 69, 122)' stroke-width='2' fill-opacity='0' /%3E%3C/svg%3E");
  position: relative;
  top: 1px;
}

[role="radio"][aria-checked="true"]:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' style='forced-color-adjust: auto;'%3E%3Ccircle cx='7' cy='7' r='6' stroke='rgb(18, 69, 122)' stroke-width='2' fill-opacity='0' /%3E%3Ccircle cx='7' cy='7' r='3' fill='rgb(18, 69, 122)' stroke-opacity='0' /%3E%3C/svg%3E");
  position: relative;
  top: 1px;
}
/*# sourceMappingURL=index.ff05a088.css.map */
